import React from "react";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useCart } from "react-use-cart";

function Navbarr() {
    const { items } = useCart()
    return (
        <div id="top">
            {/* <!-- Topbar Start --> */}
            <div id="top" className="container-fluid">
                <div className="row align-items-center py-3 px-xl-5">
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="text-decoration-none">
                            <h1 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1">E</span>Shop</h1>
                        </Link>
                    </div>
                    <div className="col-lg-6 col-6 text-left">
                        <form action="">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search for products" />
                                <div className="input-group-append">
                                    <span className="input-group-text bg-transparent text-primary">
                                        <FaSearch />
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-3 col-6 text-right">
                        <Link to="/cart" className="btn border">
                            <FaShoppingCart className="fas text-primary" />
                            <span className="badge">{items.length}</span>
                        </Link>
                    </div>
                </div>
            </div>
            {/* <!-- Topbar End --> */}


            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top px-xl-5">
                    <div className="col-lg-12">
                        <Navbar bg="light" expand="lg" className='py-3 py-lg-0 px-0'>
                                <Navbar.Brand href="#home"><h1 className="m-0 display-5 text-decoration-none d-block d-lg-none font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1">E</span>Shop</h1></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link href="/">Home</Nav.Link>
                                    <Nav.Link href="/shop">Shop</Nav.Link>
                                    <Nav.Link href="/contact">Contact Us</Nav.Link>
                                    <NavDropdown title="Collections" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/collection/puma x davido">Puma X Davido</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link href="/category/11">Men</Nav.Link>
                                    <Nav.Link href="/category/14">Women</Nav.Link>
                                    <NavDropdown title="Categories" id="basic-nav-dropdown">
                                    {/* <NavDropdown.Item> */}
                                        <NavDropdown  title="Accessories" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="/category/1">SmartPhones</NavDropdown.Item>
                                            <NavDropdown.Item href="/category/2">Laptop</NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="Home" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="/category/6">Home decoration</NavDropdown.Item>
                                            <NavDropdown.Item href="/category/7">Furniture</NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="Automobile" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="/category/18">Automotive</NavDropdown.Item>
                                            <NavDropdown.Item href="/category/19">Mortocycle</NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="Fashion" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="/category/8">Tops</NavDropdown.Item>
                                            <NavDropdown.Item href="/category/17">Sunglasses</NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="SkinCare & Fragrances" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="/category/4">SkinCare</NavDropdown.Item>
                                            <NavDropdown.Item href="/category/3">Fragrances</NavDropdown.Item>
                                        </NavDropdown>
                                            <Nav.Link href="/category/5">groceries</Nav.Link>
                                            <Nav.Link href="/category/20">Lighting</Nav.Link>
                                    {/* </NavDropdown.Item> */}
                                    </NavDropdown>
                                </Nav>
                                </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
            </div>
        </div>
    )
}
    
    



export default Navbarr;