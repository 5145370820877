import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import Notiflix from "notiflix";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Checkout() {
    const {
        items,
        isEmpty,
        emptyCart
    } = useCart();

    const discounts = [];
    let cartTotaal = 0

    
    function cartIsEmpty(){
        Notiflix.Report.info(
            'CheckOut Failed',
            'Could not Place order Because, Cart is empty',
            'Okay',
            );
    }
    

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        emptyCart()
    }
    const handleShow = () => setShow(true);

    return (
        <div>
            {/* <!-- Page Header Start --> */}
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '300px'}}>
                    <h1 className="font-weight-semi-bold text-uppercase mb-3">Checkout</h1>
                    <div className="d-inline-flex">
                        <p className="m-0"><Link to="">Home</Link></p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">Checkout</p>
                    </div>
                </div>
            </div>
            {/* <!-- Page Header End --> */}

            {/* <!-- Form Area --> */}
			<div className="container-fluid pt-5">
                <div className="row px-xl-5">
                    <div className="col-lg-8">
                        <div className="mb-4">
                            <div className="billing-details">
							<div>
                                <h3 className="font-weight-semi-bold mb-4">Billing Address</h3>
							</div>
							<div className="form-group">
								<input className="input" type="text" name="first-name" placeholder="First Name"/>
							</div>
							<div className="form-group">
								<input className="input" type="text" name="last-name" placeholder="Last Name"/>
							</div>
							<div className="form-group">
								<input className="input" type="email" name="email" placeholder="Email"/>
							</div>
							<div className="form-group">
								<input className="input" type="text" name="address" placeholder="Address"/>
							</div>
							<div className="form-group">
								<input className="input" type="text" name="city" placeholder="City"/>
							</div>
							<div className="form-group">
								<input className="input" type="text" name="country" placeholder="Country"/>
							</div>
							<div className="form-group">
								<input className="input" type="text" name="zip-code" placeholder="ZIP Code"/>
							</div>
							<div className="form-group">
								<input className="input" type="tel" name="tel" placeholder="Telephone"/>
							</div>
							<div className="form-group">
								<div className="input-checkbox">
									<input type="checkbox" id="create-account"/>
									<label htmlFor="create-account">
										<span></span>
										Create Account?
									</label>
									<div className="caption">
										<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
										<input className="input" type="password" name="password" placeholder="Enter Your Password"/>
									</div>
								</div>
							</div>
						</div>
                        </div>
                        <div className="collapse mb-4" id="shipping-address">
                            <h4 className="font-weight-semi-bold mb-4">Shipping Address</h4>
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label>First Name</label>
                                    <input className="form-control" type="text" placeholder="John" />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Last Name</label>
                                    <input className="form-control" type="text" placeholder="Doe" />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>E-mail</label>
                                    <input className="form-control" type="text" placeholder="example@email.com" />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Mobile No</label>
                                    <input className="form-control" type="text" placeholder="+123 456 789" />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Address Line 1</label>
                                    <input className="form-control" type="text" placeholder="123 Street" />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Address Line 2</label>
                                    <input className="form-control" type="text" placeholder="123 Street" />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Country</label>
                                    <select className="custom-select">
                                        <option defaultChecked >Nigeria</option>
                                        <option>Afghanistan</option>
                                        <option>Albania</option>
                                        <option>Algeria</option>
                                    </select>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>City</label>
                                    <input className="form-control" type="text" placeholder="New York" />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>State</label>
                                    <input className="form-control" type="text" placeholder="New York" />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>ZIP Code</label>
                                    <input className="form-control" type="text" placeholder="123" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card border-secondary mb-5">
                            <div className="card-header bg-secondary border-0">
                                <h4 className="font-weight-semi-bold m-0">Order Total</h4>
                            </div>
                            <div className="card-body">
                                <h5 className="font-weight-medium mb-3">Products</h5>
                                {
                                    items.map((item) => {
                                            const discount = Math.round(item.price - (item.discountPercentage / 100) * item.price)
                                            const itemTotal = discount * item.quantity
                                            discounts.push(itemTotal);
                                        return(
                                            <div className="d-flex justify-content-between">
                                                <p>{item.title}</p>
                                                <p>${itemTotal}</p>
                                            </div>                                    
                                        )
                                    })
                                }
                                <div style={{display : 'none'}}>
                                    {
                                        discounts.map((ele) => {
                                            return  cartTotaal+=ele
                                        })
                                    }
                                </div>
                                    
                                <hr className="mt-0" />
                                <div className="d-flex justify-content-between mb-3 pt-1">
                                    <h6 className="font-weight-medium">Subtotal</h6>
                                    <h6 className="font-weight-medium">${cartTotaal}</h6>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-medium">Shipping</h6>
                                    {
                                        cartTotaal < 10000? (
                                            <h6 className="font-weight-medium">Free</h6>
                                        ) : (
                                            <h6 className="font-weight-medium">${( 0.05 * cartTotaal).toFixed(2)}</h6>
                                        )
                                    }
                                    
                                </div>
                            </div>
                            <div className="card-footer border-secondary bg-transparent">
                                <div className="d-flex justify-content-between mt-2">
                                    <h5 className="font-weight-bold">Total</h5>
                                    {
                                        cartTotaal < 10000? (
                                            <h5 className="font-weight-bold">${cartTotaal}</h5>
                                        ):(
                                            <h5 className="font-weight-bold">${(cartTotaal + ( 0.05 * cartTotaal))}</h5>

                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card border-secondary mb-5">
                            <div className="card-header bg-secondary border-0">
                                <h4 className="font-weight-semi-bold m-0">Payment</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" name="payment" id="paypal" />
                                        <label className="custom-control-label" htmlFor="paypal">Paypal</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" name="payment" id="directcheck" />
                                        <label className="custom-control-label" htmlFor="directcheck">Direct Check</label>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" name="payment" id="banktransfer" />
                                        <label className="custom-control-label" htmlFor="banktransfer">Bank Transfer</label>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer border-secondary bg-transparent">
                                {
                                    isEmpty? (
                                        <button onClick={cartIsEmpty} className="btn btn-lg btn-primary btn-block font-weight-bold ">Place Order</button>
                                    ): (
                                        <Button variant="primary" onClick={handleShow} className="btn btn-lg btn-block btn-primary font-weight-bold my-3 py-3">Place Order</Button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			{/* <!-- /container --> */}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                <Modal.Title>CheckOut Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <hr />
                        <h2>Purchased Items</h2>
                        <div className="container-fluid pt-5">
                            <div className="row px-xl-5">
                                <div className="col-lg-12 table-responsive mb-5">
                                    <table className="table table-bordered text-center mb-0">
                                        <thead className="bg-secondary text-dark">
                                            <tr>
                                                <th>S/No</th>
                                                <th>Products</th>
                                                <th>Quantity</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="align-middle">
                                            {
                                                items.map((item, i) => {
                                                    const discount = Math.round(item.price - (item.discountPercentage / 100) * item.price)
                                                    const itemTotal = discount * item.quantity
                                                    discounts.push(itemTotal);
                                                    return(
                                                        <tr>
                                                            <td>{i + 1}</td>
                                                            <td>{item.title}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>$ {itemTotal}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td ></td>
                                                <td colSpan={2}>TOTAL (With Shipping)</td>
                                                {
                                                    cartTotaal < 10000? (
                                                        <td>${cartTotaal}</td>
                                                    ):(
                                                        <td>${(cartTotaal + ( 0.05 * cartTotaal))}</td>

                                                    )
                                                }
                                            </tr>
                                        </tbody>
                                    </table>                                    
                                </div>
                            </div>
                        </div>
                        
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Done
                </Button>
                </Modal.Footer>
            </Modal>
            {/* <!-- Back to Top --> */}
            
            <Link to="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></Link>
        </div>
    )
}
    
    



export default Checkout;