import React from 'react'
import puma from '../puma';
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import { FaEye, FaShoppingCart  } from "react-icons/fa";
import { Carousel } from 'antd';

function Davido() {

    const {
        addItem,
        // inCart,
    } = useCart();
    const navigate = useNavigate();

    return(
        <div>
            {/* <!-- Page Header Start --> */}
            <div className="container-fluid bg-dark">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 text-light">
                        <h1 className='text-white' style={{fontWeignt: 'bolder', fontSize: "70px"}}>Puma X Davido</h1>
                        <p>The first PUMA X DAVIDO collection is filled with bold, statement pieces inspired by the award-winning artist’s roots.</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <Carousel effect='fade' autoplay>
                            <div>
                                <img className="img-fluid" src="https://cdn.shopify.com/s/files/1/0651/6768/7916/collections/2022912_PUMA_x_Davido_NYC_Collab_4_GRAPHIC_TEE_T7_PANTS_0448.jpg" alt="bb" /> {/* ?v=1679925536&amp;width=750 */}
                            </div>
                            <div>
                                <img className="img-fluid" src="https://www.billboard.com/wp-content/uploads/2023/04/01-Davido-x-PUMA-billboard-1548.jpg" alt="cc" /> {/* ?resize=942,623 */}
                            </div>
                            <div>
                                <img width='700px' className="img-fluid" src="https://i0.wp.com/www.theafricandream.net/wp-content/uploads/2023/04/PicsArt_23-04-08_11-57-12-096.jpg?w=543&ssl=1" alt="dd" /> 
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
            {/* <!-- Page Header End --> */}


            {/* <!-- Shop Start --> */}
            <div className="container-fluid pt-5">
                <div className="row px-xl-5">
                    {/* <!-- Shop Product Start --> */}
                    <div className="col-lg-12 col-md-12">
                        <div className="row pb-3">
                            <div className="container">
                                <div className="row">
                                    {/* <!-- product --> */}
                                    {
                                        puma.map((item, id) => {
                                        const discount = item.price - (item.discountPercentage / 100) * item.price
                                        const addToCart = () => {
                                            addItem( item )
                                        }
                                        const linkTo = (e) => {
                                            e.preventDefault();
                                            navigate(`/puma x Davido/${item.id}/#`)
                                        }
                                            return(
                                                <div key={id} className="col-md-3 col-xs-6">
                                                    <div className="product">
                                                        <div className="product-img">
                                                            <img src={item.thumbnail} alt="" />
                                                            <div className="product-label">
                                                                {/* <span className="sale"></span> */}
                                                            </div>
                                                        </div>
                                                        <div className="product-body">
                                                            <p className="product-category">{item.category}</p>
                                                            <h3 className="product-name">{item.title}</h3>
                                                            <h4 className="product-price">${discount.toFixed(2)} <del className="product-old-price">${item.price}.00</del></h4>
                                                            <div className="product-rating">
                                                            </div>
                                                            <div className="product-btns">
                                                                <button onClick={linkTo} className="quick-view"><FaEye/><span className="tooltipp">quick view</span></button>
                                                                <button onClick={addToCart} className="add-to-wishlist"><FaShoppingCart /><span className="tooltipp">add to cart</span></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {/* <!-- /product --> */}
                            </div>
                            {/* <!-- /row --> */}
                        </div>
                        </div>
                    </div>
                    {/* <!-- Shop Product End --> */}
                </div>
            </div>
            {/* <!-- Shop End --> */}


        </div>
    )
}

export default Davido
