import React from "react";
import { Link } from "react-router-dom";
import { FaAngleRight, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'
function Footer() {
    return (
        <div>
            {/* <!-- Footer Start --> */}
            <div className="container-fluid bg-secondary text-dark mt-5 pt-5">
                <div className="row px-xl-5 pt-5">
                    <div className="col-lg-5 col-md-12 mb-5 pr-3 pr-xl-5">
                        <Link to="/" className="text-decoration-none">
                            <h1 className="mb-4 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border border-white px-3 mr-1">E</span>Shop</h1>
                        </Link>
                        <p className="mb-2"><FaMapMarkerAlt className="fa fa-map-marker-alt text-primary mr-3"></FaMapMarkerAlt>123 Oluyole Extension Ibadan, Oyo State, Nigeria</p>
                        <Link to="mailto:e-shop@gmail.com">
                            <p className="text-dark mb-2"><FaEnvelope className="fa fa-envelope text-primary mr-3"></FaEnvelope>e-shop@gmail.com</p>
                        </Link>
                        <Link to="tel:+2348115046415" className="text-decoration-none">
                            <p className="text-dark mb-0"><FaPhoneAlt className="fa fa-phone-alt text-primary mr-3"></FaPhoneAlt>+234 811 5046 415</p>
                        </Link>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="row">
                            <div className="col-md-8 mb-5">
                                <h5 className="font-weight-bold text-dark mb-4">Quick Links</h5>
                                <div className="d-flex flex-column justify-content-start">
                                    <Link className="text-dark mb-2" to="/"><FaAngleRight className="mr-2"></FaAngleRight>Home</Link>
                                    <Link className="text-dark mb-2" to="/shop"><FaAngleRight className="mr-2"></FaAngleRight>Our Shop</Link>
                                    <Link className="text-dark mb-2" to="/collection/puma x davido"><FaAngleRight className="mr-2"></FaAngleRight>Puma x Davido</Link>
                                    <Link className="text-dark mb-2" to="/cart"><FaAngleRight className="mr-2"></FaAngleRight>Shopping Cart</Link>
                                    <Link className="text-dark mb-2" to="/checkout"><FaAngleRight className="mr-2"></FaAngleRight>Checkout</Link>
                                    <Link className="text-dark" to="/contact"><FaAngleRight className="mr-2"></FaAngleRight>Contact Us</Link>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <h5 className="font-weight-bold text-dark mb-4">Newsletter</h5>
                                <form action="">
                                    <div className="form-group">
                                        <input type="text" className="form-control border-0 py-4" placeholder="Your Name" required="required" />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control border-0 py-4" placeholder="Your Email /"
                                            required="required" />
                                    </div>
                                    <div>
                                        <button className="btn btn-primary btn-block border-0 py-3" type="submit">Subscribe Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row border-top border-light mx-xl-5 py-4">
                    <div className="col-md-6 px-xl-0">
                        <p className="mb-md-0 text-center text-md-left text-dark">
                            &copy; <Link className="text-dark font-weight-semi-bold" to="/">E-Shop</Link>. All Rights Reserved. Designed
                            by Tunz
                        </p>
                    </div>
                    <div className="col-md-6 px-xl-0 text-center text-md-right">
                        <img className="img-fluid" src="../img/payments.png" alt="" />
                    </div>
                </div>
            </div>

            <Link to="/#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></Link>
        </div>
    )
}
    
    



export default Footer;