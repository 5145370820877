const puma = [
    {
        "id": 101,
        "title": "PUMA x Davido AOP Tee",
        "details" : "The first PUMA x DAVIDO collection is filled with bold, statement pieces inspired by the award-winning artist’s roots ­– including this tee with its all-over graphic print.",
        "description": [
            'Regular fit',
            'Rib crew neck',
            'All-over-print',
        ],
        "price": 62,
        "discountPercentage": 17.94,
        "rating": 4.44,
        "stock": 34,
        "brand": "PUMA",
        "category": "PUMA",
        "thumbnail": "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620407_27_mod04.png?v=1679749760&width=500",
        "images": [
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620407_27.png?v=1679749761&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620407_27_mod02.png?v=1679749762&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620407_27_mod05.png?v=1679749761&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620407_27_mod03.png?v=1679749762&width=500"
        ]
    },
    {
        "id": 102,
        "title": "PUMA x Davido Logo Tee",
        "details" : "The first PUMA x DAVIDO collection is filled with bold, statement pieces inspired by the award-winning artist’s roots ­– including this classic logo tee.",
        "description": [
            'Regular fit',
            'Rib crew neck',
            'All-over-print',
        ],
        "price": 60,
        "discountPercentage": 15.95,
        "rating": 4.44,
        "stock": 34,
        "brand": "PUMA",
        "category": "PUMA",
        "thumbnail": "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/SS23_Pre-Launch_PUMA_x_Davido_2_AOP_TEE_GRAPHIC_SHORTS_6901.jpg?v=1679935984&width=500",
        "images": [
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620408_02_mod1.png?v=1679935984&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620408_02.png?v=1679935984&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620408_02_mod4.png?v=1679935984&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620408_02_mod3.png?v=1679935984&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620408_02_mod5.png?v=1679935984&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620408_02_mod5.png?v=1679935984&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620408_02_mod2.png?v=1679935984&width=500",
        ]
    },
    {
        "id": 103,
        "title": "PUMA x Davido Graphic Tee",
        "details" : "The first PUMA x DAVIDO collection is filled with bold, statement pieces inspired by the award-winning artist’s roots ­– including this classic graphic tee.",
        "description": [
            "Regular fit",
            "Rib crew neck",
            "All-over-print",
        ],
        "price": 60,
        "discountPercentage": 15.62,
        "rating": 4.44,
        "stock": 34,
        "brand": "PUMA",
        "category": "PUMA",
        "thumbnail": "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620409_01_mod01.png?v=1679751192&width=500",
        "images": [
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620409_01_mod03.png?v=1679751191&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620409_01.png?v=1679751208&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620409_01_mod02.png?v=1679751208&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620409_01_bv.png?v=1679751208&width=500"
        ]
    },
    {
        "id": 104,
        "title": "PUMA x Davido T7 Jacket AOP",
        "details" : "The first PUMA x DAVIDO collection is filled with bold, statement pieces inspired by the award-winning artist’s roots ­– including this rendition of our classic T7 jacket, decorated in all over print.",
        "description": [
            "Regular fit",
            "Stand up collar",
            "Full-zip closure",
            "Monogram all over print",
            "T7 panel inserts on the sleeves",
            "Embroidered Archive No.1 logo on chest",
        ],
        "price": 134,
        "discountPercentage": 17.94,
        "rating": 4.44,
        "stock": 34,
        "brand": "PUMA",
        "category": "PUMA",
        "thumbnail": "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620410_27_mod01.png?v=1679751657&width=500",
        "images": [
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620410_27_mod05.png?v=1679751657&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620410_27_mod04.png?v=1679751657&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620410_27.png?v=1679751657&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620410_27_mod03.png?v=1679751657&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620410_27_mod03.png?v=1679751657&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620410_27_mod02.png?v=1679751657&width=500"
        ]
    },
    {
        "id": 105,
        "title": "PUMA x Davido T7 Pants",
        "details" : "The first PUMA x DAVIDO collection is filled with bold, statement pieces inspired by the award-winning artist’s roots ­– including this T7 pants, decorated in all over print.",
        "description": [
            "Regular fit",
            "Medium rise",
            "Elastic waistband with external drawcords",
            "Monogram all over print",
            "T7 panel on side",
            "Welt side seam pockets",
        ],
        "price": 116,
        "discountPercentage": 17.29,
        "rating": 4.44,
        "stock": 34,
        "brand": "PUMA",
        "category": "PUMA",
        "thumbnail": "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620411_27_mod01.png?v=1679751978&width=500",
        "images": [
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620411_27_mod03.png?v=1679845800&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620411_27.png?v=1679845800&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620411_27_mod04.png?v=1679845800&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620411_27_bv.png?v=1679751978&width=500"
        ]
    },
    {
        "id": 106,
        "title": "PUMA x Davido Shorts Tee",
        "details" : "The first PUMA x DAVIDO collection is filled with bold, statement pieces inspired by the award-winning artist’s roots ­– including this rendition of our classic graphic shorts",
        "description": [
            "Regular fit",
            "Medium rise",
            "Elastic waistband with external drawcords",
            "Welt side seam pockets",
        ],
        "price": 60,
        "discountPercentage": 14.83,
        "rating": 4.44,
        "stock": 34,
        "brand": "PUMA",
        "category": "PUMA",
        "thumbnail": "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620412_01_mod04.png?v=1679752390&width=500",
        "images": [
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620412_01.png?v=1679845359&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620412_01_mod05.png?v=1679845359&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620412_01_mod01.png?v=1679752390&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/620412_01_mod03.png?v=1679752390&width=500"
        ]
    },
    {
        "id": 107,
        "title": "PUMA x Davido BB Cap",
        "details" : "The first PUMA x DAVIDO collection is filled with bold, statement pieces inspired by the award-winning artist’s roots ­– including this classic cap",
        "description": [
            "6-panel shape",
            "Curved brim",
            "Self fabric adjuster with metal D-ring",
            "Embroidered PUMA Archive No. 1 Logo on the front",
        ],
        "price": 54,
        "discountPercentage": 13.54,
        "rating": 4.44,
        "stock": 34,
        "brand": "PUMA",
        "category": "PUMA",
        "thumbnail": "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/024778_01.png?v=1679784531&width=500",
        "images": [
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/024778_01_mod03.png?v=1679784531&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/024778_01_bv.png?v=1679784531&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/024778_01_mod02.png?v=1679784530&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/024778_01_mod01.png?v=1679784531&width=500"
        ]
    },
    {
        "id": 108,
        "title": "PUMA x Davido Reversible Bucket",
        "details" : "The first PUMA x DAVIDO collection is filled with bold, statement pieces inspired by the award-winning artist’s roots ­– including this reversible bucket hat with its all-over graphic on one side.",
        "description": [
            "Reversible",
            "All-over graphic on one side",
            "PUMA branding details",
        ],
        "price": 54,
        "discountPercentage": 13.54,
        "rating": 4.44,
        "stock": 34,
        "brand": "PUMA",
        "category": "PUMA",
        "thumbnail": "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/024777_01_mod01.png?v=1679784615&width=500",
        "images": [
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/024777_01_dt01.png?v=1679784636&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/024777_01.png?v=1679784636&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/024777_01_mod03.png?v=1679784636&width=500",
        "https://cdn.shopify.com/s/files/1/0651/6768/7916/products/024777_01_bv.png?v=1679784636&width=500"
        ]
    },
]

export default puma