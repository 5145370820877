import React from "react";
import { FaEye, FaShoppingCart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
function ShopItem({item}) {
    const discount = item.price - (item.discountPercentage / 100) * item.price
    
    const {
        addItem,
    } = useCart();
    
    const addToCart = () => {
        addItem( item )
    }
    
    return(
        <div className="col-lg-4 col-md-6 col-sm-12 pb-1">
            <div style={{height: '350px'}} className="card product-item border-0 mb-4">
                <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                    <img className="img-fluid w-100" src={item.thumbnail} alt={item.title}/>
                </div>
                <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h5 className="text-truncate mb-3">{item.title}</h5>
                    <small className="mb-3">{item.category}</small>
                    <div className="d-flex justify-content-center">
                        <h6>${discount.toFixed(2)}</h6><h6 className="text-muted ml-2"><del>${item.price}</del></h6>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-between bg-light border">
                    <Link to={`/details/${item.id}`} className="btn btn-sm text-dark p-0"><FaEye className="text-primary mr-1" style={{fontSize: "18px"}} />View Detail</Link>
                    <button onClick={addToCart} className="btn btn-sm text-dark p-0" >Add To Cart <FaShoppingCart className="text-primary mr-1" style={{fontSize: "18px"}} /></button>
                </div>
            </div>
        </div>
    )
}

export default ShopItem;
