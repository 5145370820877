import React from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

function Contact() {
    return (
        <div>
            {/* <!-- Page Header Start --> */}
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '300px'}}>
                    <h1 className="font-weight-semi-bold text-uppercase mb-3">Contact Us</h1>
                    <div className="d-inline-flex">
                        <p className="m-0"><Link to="">Home</Link></p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">Contact</p>
                    </div>
                </div>
            </div>
            {/* <!-- Page Header End --> */}


            {/* <!-- Contact Start --> */}
            <div className="container-fluid pt-5">
                <div className="text-center mb-4">
                    <h2 className="section-title px-5"><span className="px-2">Contact For Any Queries</span></h2>
                </div>
                <div className="row px-xl-5">
                    <div className="col-lg-7 mb-5">
                        <div className="contact-form">
                            <div id="success"></div>
                            <form name="sentMessage" id="contactForm" noValidate="novalidate">
                                <div className="control-group">
                                    <input type="text" className="form-control" id="name" placeholder="Your Name"
                                        required="required" data-validation-required-message="Please enter your name" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="email" className="form-control" id="email" placeholder="Your Email"
                                        required="required" data-validation-required-message="Please enter your email" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="text" className="form-control" id="subject" placeholder="Subject"
                                        required="required" data-validation-required-message="Please enter a subject" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <textarea className="form-control" rows="6" id="message" placeholder="Message"
                                        required="required"
                                        data-validation-required-message="Please enter your message"></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div>
                                    <button className="btn btn-primary py-2 px-4" type="submit" id="sendMessageButton">Send
                                        Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5 mb-5">
                        <h5 className="font-weight-semi-bold mb-3">Get In Touch</h5>
                        <div className="d-flex flex-column mb-3">
                            <h5 className="font-weight-semi-bold mb-3">Store 1</h5>
                            <p className="mb-2"><FaMapMarkerAlt className="text-primary mr-3"/>123 Oluyole Extension Ibadan, Oyo State, Nigeria</p>
                            <Link to="mailto:e-shop@gmail.com">
                                <p className="text-dark mb-2"><FaEnvelope className="text-primary mr-3" />e-shop@gmail.com</p>
                            </Link>
                            <Link to="tel:+2348115046415">
                                <p className="text-dark mb-2"><FaPhoneAlt className="text-primary mr-3" /> +234 811 5046 415</p>
                            </Link>
                        </div>
                        <div className="d-flex flex-column">
                            <h5 className="font-weight-semi-bold mb-3">Store 2</h5>
                            <p className="mb-2"><FaMapMarkerAlt className="text-primary mr-3"/>14 Oluyole Extension Ibadan, Oyo State, Nigeria</p>
                            <Link to="mailto:otunbaodubanjo@gmail.com">
                                <p className="text-dark mb-2"><FaEnvelope className="text-primary mr-3" />otunbaodubanjo@gmail.com</p>
                            </Link>
                            <Link to="https://wa.me/+2348115046415">
                                <p className="text-dark mb-0"><FaWhatsapp className="text-primary mr-3"/>+234 811 5046 415</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Contact End --> */}


            {/* <!-- Back to Top --> */}
            <Link to="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></Link>
        </div>
    )
}
    
    



export default Contact;