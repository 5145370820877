import React from "react";
import { Link } from "react-router-dom";
// import Carousel from 'react-bootstrap/Carousel';
import { Carousel } from 'antd';
import products from "../products";
import { useCart } from "react-use-cart";
import { useParams } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaMinus, FaPinterest, FaPlus, FaShoppingCart, FaStar, FaStarHalfAlt, FaTwitter } from "react-icons/fa";

function Detail() {

    const {
        addItem,
    } = useCart()

    const { id } = useParams();
    const findProduct = products.find((prod) => prod.id === Number(id));
    
    const addToCart = () => {
        addItem( findProduct )
    }
    
    const discount = findProduct.price - (findProduct.discountPercentage / 100) * findProduct.price
    return (
        <div>
            {/* <!-- Page Header Start --> */}
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '300px'}}>
                    <h1 className="font-weight-semi-bold text-uppercase mb-3">Shop Detail</h1>
                    <div className="d-inline-flex">
                        <p className="m-0"><Link to="">Home</Link></p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">Shop Detail</p>
                    </div>
                </div>
            </div>
            {/* <!-- Page Header End --> */}


            {/* <!-- Shop Detail Start --> */}
            <div className="container-fluid py-5">
                <div className="row px-xl-5">
                    <div className="col-lg-5 pb-5">
                    <Carousel effect="fade" arrows autoplay >
                        {
                            findProduct.images.map((itemImg, i) => {
                                return(
                                    <div>
                                        <img
                                         src={itemImg} 
                                         className="d-block w-100" 
                                         alt="imag" />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                    </div>

                    <div className="col-lg-7 pb-5">
                        <h3 className="font-weight-semi-bold">{findProduct.title}</h3>
                        <div className="d-flex mb-3">
                            <div className="text-primary mr-2">
                                <small className="fas fa-star"><FaStar /></small>
                                <small className="fas fa-star"><FaStar /></small>
                                <small className="fas fa-star"><FaStar /></small>
                                <small className="far fa-star"><FaStar /></small>
                                <small className="fas fa-star-half-alt"><FaStarHalfAlt /></small>
                            </div>
                            <small className="pt-1">(50 Reviews)</small>
                        </div>
                        <div className="d-flex ">
                            <h3 className="font-weight-semi-bold mb-4">${discount.toFixed(2)}</h3><h5 className="text-muted ml-2"><del>${findProduct.price}</del></h5>
                        </div>
                        <p className="mb-4">{findProduct.description}</p>
                        <div className="d-flex mb-3">
                            <p className="text-dark font-weight-medium mb-0 mr-3">Sizes:</p>
                            <form>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="size-1" name="size" />
                                    <label className="custom-control-label" htmlFor="size-1">XS</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="size-2" name="size" />
                                    <label className="custom-control-label" htmlFor="size-2">S</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="size-3" name="size" />
                                    <label className="custom-control-label" htmlFor="size-3">M</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="size-4" name="size" />
                                    <label className="custom-control-label" htmlFor="size-4">L</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="size-5" name="size" />
                                    <label className="custom-control-label" htmlFor="size-5">XL</label>
                                </div>
                            </form>
                        </div>
                        <div className="d-flex mb-4">
                            <p className="text-dark font-weight-medium mb-0 mr-3">Colors:</p>
                            <form>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="color-1" name="color" />
                                    <label className="custom-control-label" htmlFor="color-1">Black</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="color-2" name="color" />
                                    <label className="custom-control-label" htmlFor="color-2">White</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="color-3" name="color" />
                                    <label className="custom-control-label" htmlFor="color-3">Red</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="color-4" name="color" />
                                    <label className="custom-control-label" htmlFor="color-4">Blue</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="color-5" name="color" />
                                    <label className="custom-control-label" htmlFor="color-5">Green</label>
                                </div>
                            </form>
                        </div>
                        <div className="d-flex align-items-center mb-4 pt-2">
                            <div className="input-group quantity mr-3" style={{width: '130px'}}>
                                <div className="input-group-btn">
                                    <button className="btn btn-primary btn-minus" >
                                        <FaMinus />
                                    </button>
                                </div>
                                <input type="text" className="form-control bg-secondary text-center" readOnly  value="1" />
                                <div className="input-group-btn">
                                    <button className="btn btn-primary btn-plus">
                                        <FaPlus />
                                    </button>
                                </div>
                            </div>
                            <button onClick={addToCart} className="btn btn-primary px-3"><FaShoppingCart className="mr-1"/> Add To Cart</button>
                        </div>
                        <div className="d-flex pt-2">
                            <p className="text-dark font-weight-medium mb-0 mr-2">Share on:</p>
                            <div className="d-inline-flex">
                                <Link className="text-dark px-2" to="">
                                    <FaFacebook />
                                </Link>
                                <Link className="text-dark px-2" to="">
                                    <FaTwitter />
                                </Link>
                                <Link className="text-dark px-2" to="">
                                    <FaLinkedin />
                                </Link>
                                <Link className="text-dark px-2" to="">
                                    <FaPinterest />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Shop Detail End --> */}


            {/* <!-- Back to Top --> */}
            <Link to="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></Link>
        </div>
    )
}
    
    



export default Detail;