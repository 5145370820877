import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import Notiflix from "notiflix";
import { FaMinus, FaPlus, FaTimes } from 'react-icons/fa'
function Cart() {
    
    const {
        isEmpty,
        items,
        updateItemQuantity,
        removeItem,
        emptyCart,
    } = useCart();
    let discount  ;
    const discounts = [];
    let cartTotaal = 0;

    
    return (
        <div>
            {/* <!-- Page Header Start --> */}
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '300px'}}>
                    <h1 className="font-weight-semi-bold text-uppercase mb-3">Shopping Cart</h1>
                    <div className="d-inline-flex">
                        <p className="m-0"><Link href="">Home</Link></p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">Shopping Cart</p>
                    </div>
                </div>
            </div>
            {/* <!-- Page Header End --> */}


            {/* <!-- Cart Start --> */}
            <div className="container-fluid pt-5">
                <div className="row px-xl-5">
                    <div className="col-lg-8 table-responsive mb-5">
                        <p className="text-primary">Scroll Vertically to view full table</p>
                        <table className="table table-bordered text-center mb-0">
                            <thead className="bg-secondary text-dark">
                                <tr>
                                    <th>Products</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody className="align-middle">
                                {
                                    isEmpty ? (
                                    <h3 style={{ color: "red" }}>The cart is empty</h3>
                                    ) : (
                                        items.map((item , id) => {
                                          const confirmDelete = (id) => {
                                              Notiflix.Confirm.show(
                                              "E-Shop Confirm",
                                              "Are you sure you want to delete?",
                                              "Delete",
                                              "Cancel",
                                              function okCb() {
                                                  removeItem(item.id);
                                                  Notiflix.Notify.success('Item Delete successfully')
                                              },
                                              function cancelCb() {},
                                              {
                                                  width: "320px",
                                                  borderRadius: "8px",
                                                  // okButtonBackground: "purple",
                                                  // cancelButtonBackground: "red",
                                                  // titleColor: "deeppink",
                                                  // etc...
                                              }
                                              );
                                          };
                                            discount = Math.round(item.price - (item.discountPercentage / 100) * item.price)
                                            const itemTotal = discount * item.quantity
                                            discounts.push(itemTotal);

                                            return(
                                                <tr key={id}>
                                                    <td className="align-middle"><img src={item.thumbnail} alt="" style={{width: '50px'}}/>{item.title}</td>
                                                    <td className="align-middle">${Math.round(discount)}</td>
                                                    <td className="align-middle">
                                                        <div className="input-group quantity mx-auto" style={{width: '100px'}}>
                                                            <div className="input-group-btn">
                                                              {
                                                                item.quantity === 1?
                                                                (
                                                                  <button onClick={()=> updateItemQuantity(item.id, item.quantity - 1)} disabled className="btn btn-sm btn-primary btn-minus" >
                                                                    <FaMinus />
                                                                  </button>
                                                                ):(
                                                                  <button onClick={()=> updateItemQuantity(item.id, item.quantity - 1)} className="btn btn-sm btn-primary btn-minus" >
                                                                    <FaMinus />
                                                                  </button>
                                                                )
                                                              }
                                                            </div>
                                                            {/* <input type="text" className="form-control form-control-sm bg-secondary text-center" value={item.quantity} /> */}
                                                            <button className="form-control form-control-sm bg-secondary text-center">{item.quantity}</button>
                                                            <div class="input-group-btn">
                                                                <button onClick={()=> updateItemQuantity(item.id, item.quantity + 1)} class="btn btn-sm btn-primary btn-plus">
                                                                    <FaPlus />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="align-middle">${itemTotal}</td>
                                                    <td class="align-middle"><button onClick={confirmDelete} class="btn btn-sm btn-primary"><FaTimes /> </button></td>
                                                </tr>
                                            )
                                        })
                                    )
                                }
                                {
                                    
                                }
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><button onClick={confirmEmpty} className="btn btn-primary">Clear Cart</button></td>                                    
                                </tr>
                            </tbody>
                        </table>
                        <div style={{display : 'none'}}>
                            {
                                discounts.map((ele) => {
                                    return  cartTotaal+=ele
                                })
                            }
                        </div>
                    </div>
                    
                    <div class="col-lg-4">
                        <form class="mb-5" action="">
                            <div class="input-group">
                                <input type="text" class="form-control p-4" placeholder="Coupon Code" />
                                <div class="input-group-append">
                                    <button class="btn btn-primary">Apply Coupon</button>
                                </div>
                            </div>
                        </form>
                        <div class="card border-secondary mb-5">
                            <div class="card-header bg-secondary border-0">
                                <h4 class="font-weight-semi-bold m-0">Cart Summary</h4>
                            </div>
                            <div class="card-body">
                                <div className="d-flex justify-content-between mb-3 pt-1">
                                    <h6 className="font-weight-medium">Subtotal</h6>
                                    <h6 className="font-weight-medium">${cartTotaal}</h6>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-medium">Shipping: </h6>
                                    {
                                        cartTotaal < 10000? (
                                            <h6 className="font-weight-medium"> Free for Products less than $10k</h6>
                                        ) : (
                                            <h6 className="font-weight-medium">${( 0.05 * cartTotaal)}</h6>
                                        )
                                    }
                                    
                                </div>
                            </div>
                            <div className="card-footer border-secondary bg-transparent">
                                <div className="d-flex justify-content-between mt-2">
                                    <h5 className="font-weight-bold">Total</h5>
                                    {
                                        cartTotaal < 10000? (
                                            <h5 className="font-weight-bold">${cartTotaal}</h5>
                                        ):(
                                            <h5 className="font-weight-bold">${(cartTotaal + ( 0.05 * cartTotaal))}</h5>

                                        )
                                    }
                                </div>
                                <Link to='/checkout'>
                                    <button className="btn btn-block btn-primary my-3 py-3">Proceed To Checkout</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Cart End --> */}
        </div>
    )
    function confirmEmpty() {
        Notiflix.Confirm.show(
        "E-Shop Confirm",
        "Are you sure you want to Empty the Cart?",
        "Empty",
        "Cancel",
        function okCb() {
            emptyCart()
            Notiflix.Notify.warning('Cart Emptied successfully')
        },
        function cancelCb() {},
        {
            width: "320px",
            borderRadius: "8px",
            // okButtonBackground: "purple",
            // cancelButtonBackground: "red",
            // titleColor: "deeppink",
            // etc...
        }
        );
    }
    
}

export default Cart;