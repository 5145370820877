import React from "react";
import { useCart } from "react-use-cart";
import { Link } from "react-router-dom";
import { FaCartPlus, FaEye } from 'react-icons/fa'

function HomeItem({ item, id}) {
    const {
        addItem,
    } = useCart();
    
    const addToCart = () => {
        addItem( item )
    }

    const discount = item.price - (item.discountPercentage / 100) * item.price
                          
    return(
        <div key={id} className="col-lg-3 col-md-6 col-sm-12 pb-1">
            <div style={{height: '350px'}} className="card product-item border-0 mb-4">
                <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                    <img className="img-fluid w-100" src={item.thumbnail} alt={item.title + '  image'} />
                </div>
                <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                    <h6 className="text-truncate mb-3">{item.title}</h6>
                    <div className="d-flex justify-content-center">
                        <h6>${discount.toFixed(2)}</h6><h6 className="text-muted ml-2"><del>${item.price}</del></h6>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-between bg-light border">
                    <Link to={`/details/${item.id}`} preventScrollReset={false} className="btn btn-sm text-dark p-0"><i style={{fontSize: "18px"}} className="fas fa-eye text-primary mr-1"><FaEye /></i> View Detail</Link>
                    <button onClick={addToCart} className="btn btn-sm text-dark p-0">Add To Cart <i style={{fontSize: "18px"}} className="fas fa-shopping-cart text-primary mr-1"> <FaCartPlus /></i></button>
                </div>
            </div>
        </div>
    )
}

export default HomeItem;